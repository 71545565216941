import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { Snackbar, Alert, Button } from '@mui/material';
import Logo from "../assets/images/mail-logo.png";
import In from "../assets/images/instagram.png";
import Fb from "../assets/images/facebook.png";
import Tw from "../assets/images/twitter.png";
import Lk from "../assets/images/linkdin.png";
import Footer from './Footer';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[A-Za-z0-9._%+-]+$/, 'Invalid email prefix')
    .required('Required'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters'),
});

const Login = () => {
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const login = "Login";
  const navigate = useNavigate();
  const selectedDomain = '@mailread.org';
  
  const loginUser = async (values) => {
    try {
      const fullEmail = values.email + selectedDomain;
      const credentials = `${fullEmail}:${values.password}`;
      const base64Credentials = btoa(credentials);
  
      const formData = new URLSearchParams();
      formData.append('email', fullEmail);
      formData.append('password', values.password);
  
      const response = await fetch(`https://mailread.org/api/ipn_register.php?Type=${login}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });
  
      const responseData = await response.json();
      
      if (responseData.status === "ok") {
        sessionStorage.setItem('email', responseData.email);
        navigate('/Dashboard');
      } else {
        setError("Invalid Email or Password");
        setOpen(true);
        console.error('Login failed:', responseData.error); 
      }
     
    } catch (error) {
      setError('Failed to login');
      console.error('Error logging in:', error);
      setOpen(true);
    }
  };
  
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    await loginUser(values);
    setSubmitting(false);
  };

  return (
    <div>
      <header className="header-main">
        <div className="upper-header container">
          <Link className="btn btn-sign-up" to="/">Sign-Up</Link>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand p-0" to="/"><img src={Logo} alt="Logo" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul className="navbar-nav header-links">
                 <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/#features">Features</a>
                </li> 
                 <li className="nav-item">
                  <a className="nav-link" href="#about-us">About Us</a>
                </li> 
                 <li className="nav-item">
                  <a className="nav-link" href="#how-to-use">How To Use</a>
                </li> 
                 <li className="nav-item">
                  <a className="nav-link" href="#pricing">Pricing</a>
                </li> 
                 {/* <li className="nav-item">
                  <a className="nav-link" href="#faq">FAQ'S</a>
                </li>  */}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section className="banner-main">
        <div className="container">
          <div className="row welcome-back">
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="heading-lines text-center">
                <h2>Welcome <br /> back to Login
                  <div className="lines-box">
                    <div className="lines mb-1"></div>
                    <div className="lines"></div>
                  </div>
                </h2>
              </div>
             {/* <a href="/login" className="btn btn-primary">Login</a> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 login-form">
              <div className="form-container">
                <Snackbar 
                  open={open} 
                  autoHideDuration={4000} 
                  onClose={() => setOpen(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert severity="error" onClose={() => setOpen(false)}>
                    {error}
                  </Alert>
                </Snackbar>

                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    remember_me: false,
                  }}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <div className="input-group">
                        <Field type="text" className="form-control" name="email" id="email" />
                        <span className="input-group-text">{selectedDomain}</span>
                      </div>
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Field type="password" className="form-control" name="password" id="password" />
                      <ErrorMessage name="password" component="div" className="text-danger" />
                    </div>
                    <div className="form-group">
                      {/* <div className="form-check">
                        <Field type="checkbox" className="form-check-input" name="remember_me" id="remember_me" />
                        <label className="form-check-label" htmlFor="remember_me">Keep me logged in</label>
                      </div> */}
                    </div>
                    <div className="form-group">
                      <div className="d-grid">
                        <Button  type="submit" className = "btn btn-primary">Login</Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-main">
        <div className="container footer">
            <img src={Logo} alt=""/>
 <p className="text-center footer-text">Welcome to MailRead, where we're redefining the way you think about email. Our platform empowers you to transform your everyday emails into a valuable source of income. </p>
    <div className="footer-basic">
        <footer>
            <ul className="footer-link">
                <li className="nav-item">
                    <Link className="nav-link" aria-current="page" href="/">Home</Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#feature">Features</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#about">About Us</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#how">How To Use</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#pricing">Pricing</a>
                </li>
                {/* <li className="nav-item">
                    <a className="nav-link" href="/#faq">FAQ'S</a>
                </li> */}
            </ul>
            <div className="footer-icons">
                <img src={In} className="img-fluid" alt=""/>
                <img src={Fb} className="img-fluid" alt=""/>
                <img src={Tw} className="img-fluid" alt=""/>
                <img src={Lk} className="img-fluid" alt=""/>
            </div>
            </footer>
            </div>
            </div>
        <p className="copyright text-center">© 2024 Copyright:All rights reserved.</p>
    </footer>
    </div>
  );
};

export default Login;
